import React, { useContext, useState } from 'react'
import { FooterMobile, HeaderMobile } from 'components'
import { Context } from 'helpers/language-provider'

import { H3, H5 } from 'assets/images'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useParams } from 'react-router-dom'
import { ElectrumBlue } from 'assets/icons'
import { FormattedMessage } from 'react-intl'

import ProductH1 from './h1'
import ProductH3 from './h3'
import ProductH3i from './h3i'
import ProductH5 from './h5'

const defaults = require('languages/id.json')

const Product = () => {
  const navigate = useNavigate()

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const { type } = useParams()

  return (
    <div style={{ backgroundColor: '#EBF0F5' }}>
      <HeaderMobile />

      { type === 'h1' && <ProductH1 /> }
      { type === 'h3' && <ProductH3 /> }
      { type === 'h3i' && <ProductH3i /> }
      { type === 'h5' && <ProductH5 /> }

      <FooterMobile />
    </div>
  )
}

export default Product
