import { getNewsFromCMS } from 'apis/api'
import { useQuery } from 'react-query'

export const useGetNewsFromCMS = () => {
  return useQuery(
    ['cms-news-list'],
    () => getNewsFromCMS(),
    { keepPreviousData: true },
  )
}
