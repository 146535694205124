import * as React from "react"

const Diamond = ({ size = "26" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 26 26"
  >
    <path fill="#1ECECA" d="M0 13 12.728.272 25.456 13 12.728 25.728z"></path>
  </svg>
)

export default Diamond
