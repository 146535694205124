import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LanguageProvider, useIsMobile, ScrollToTop } from 'helpers'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Home,
  Product,
  Energy,
  GetYours,
  AboutUs,
  News,
  NewsDetail,
  PrivacyPolicy,
  SepekanForm,
  B2BHome,
} from 'pages'

import {
  MobileHome,
  MobileProduct,
  MobileEnergy,
  MobileGetYours,
  MobileAboutUs,
  MobileNews,
  MobileNewsDetail,
  MobilePrivacyPolicy,
  MobileSepekanForm,
} from 'mobile-pages'

const queryClient = new QueryClient()

const App = () => {
  const isMobile = useIsMobile()

  // ROUTES
  const homeRoute = '/'
  const productsRoute = '/products/:type'
  const energyRoute = '/energy'
  const getYoursRoute = '/get-yours'
  const aboutUsRoute = '/about-us'
  const newsRoute = '/news'
  const newsDetailRoute = '/news/detail/:slug'
  const sepekanRoute = '/sepekan-form'
  const privacyPolicyRoute = '/privacy-policy'
  const b2bHomeRoute = '/b2b'

  // DESKTOP ROUTES
  const desktopRoutes = () => {
    return (
      <Router>
        <LanguageProvider>
          <ScrollToTop />
          <Routes>
            <Route path={homeRoute} element={<Home />} />
            <Route path={productsRoute} element={<Product />} />
            <Route path={energyRoute} element={<Energy />} />
            <Route path={getYoursRoute} element={<GetYours />} />
            <Route path={aboutUsRoute} element={<AboutUs />} />
            <Route path={newsRoute} element={<News />} />
            <Route path={newsDetailRoute} element={<NewsDetail />} />
            <Route path={privacyPolicyRoute} element={<PrivacyPolicy />} />
            <Route path={sepekanRoute} element={<SepekanForm />} />
            <Route path={b2bHomeRoute} element={<B2BHome />} />
          </Routes>
        </LanguageProvider>
      </Router>
    )
  }

  // MOBILE ROUTES
  const mobileRoutes = () => {
    return (
      <Router>
        <LanguageProvider>
          <ScrollToTop />
          <Routes>
            <Route path={homeRoute} element={<MobileHome />} />
            <Route path={productsRoute} element={<MobileProduct />} />
            <Route path={energyRoute} element={<MobileEnergy />} />
            <Route path={getYoursRoute} element={<MobileGetYours />} />
            <Route path={aboutUsRoute} element={<MobileAboutUs />} />
            <Route path={newsRoute} element={<MobileNews />} />
            <Route path={newsDetailRoute} element={<MobileNewsDetail />} />
            <Route path={privacyPolicyRoute} element={<MobilePrivacyPolicy />} />
            <Route path={sepekanRoute} element={<MobileSepekanForm />} />
          </Routes>
        </LanguageProvider>
      </Router>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        autoClose={2000}
        position="top-center"
      />
      {isMobile ? mobileRoutes() : desktopRoutes()}
    </QueryClientProvider>
  )
}

export default App
