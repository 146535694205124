import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from 'helpers/language-provider'

import { ArrowRight, Thunder } from 'assets/icons'
import { AboutTD1, AboutTD2, AboutTD3, AboutTD4, BatteryCharge, BatterySwap, ColorAmber, ColorBeige, ColorGrey, ColorIceNavy, ColorIvoryWhite, ColorRedCherry, ColorViridianGreen, EfficientPlaceholder, Feature, GetNowPlaceholder, IntroProduct, PBB, PSB, PackageBG, Placeholder2, ProductEV, ProductKV, ProductKVH3i, ProductLarge, ProductSmall, RectanglePlaceholder, TechDesign1, TechDesign2, TechDesign3, Understand1, Understand2, Understand3, Understand4, Understand5, Understand6 } from 'assets/images'
import { H3iVideo, SampleVideo } from 'assets/videos'
import styled from 'styled-components'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const bannerPlaceholder = 'https://i.postimg.cc/fRNsGnYs/Frame-811270.png'

const ProductH3i = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')
  const navigate = useNavigate()

  // BUTTON STYLES
  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }
  const btnStyleWhite = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '2px solid white',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }

  // KV BANNER
  const [currentKVIndex, setCurrentKVIndex] = useState(0)
  const bannerList = [
    {
      banner_img: ProductKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
  ]
  const renderKVCarousel = () => {
    return bannerList.map((item, index) => (
      <div key={index}>
        <div
          alt="Banner Placeholder"
          style={{
            backgroundImage: `url('${item.banner_img}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100%',
            scrollSnapAlign: 'start',
          }}
        >
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '6.5rem' }}>
            {/* HEADLINE & SUBHEADLINE */}
            <div style={{ fontFamily: 'Gilroy-Bold', color: 'white', fontSize: '4.475rem' }}>
              {item.headline}
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: 'white', fontSize: '2.5rem' }}>
              {item.subheadline}
            </div>
            {/* BUTTONS */}
            <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
              <button style={btnStyleBlue}>
                {item.button1_text} <ArrowRight fill="#fff" />
              </button>
              <button style={btnStyleWhite}>
                {item.button2_text} <ArrowRight fill="#000" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ))
  }
  const handleKVCarouselClick = (index) => {
    setCurrentKVIndex(index)
    const carousel = document.getElementById('kv-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'smooth' })
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const nextIndex = (currentKVIndex + 1) % bannerList.length
  //     setCurrentKVIndex(nextIndex)
  //     const carousel = document.getElementById('kv-carousel')
  //     carousel.scrollTo({ left: nextIndex * window.innerWidth, behavior: 'smooth' })
  //   }, 3500)
  //   return () => clearInterval(interval)
  // }, [currentKVIndex, bannerList.length])

  // COLOR BANNER
  const [currentColorIndex, setCurrentColorIndex] = useState(0)
  const colorList = [
    { img: ColorIvoryWhite, color: 'Ivory White', palette: '#e5e5e5' },
    // { img: ColorRedCherry, color: 'Red Cherry', palette: '#f04c41' },
    // { img: ColorAmber, color: 'Amber', palette: '#f49f25' },
    { img: ColorIceNavy, color: 'Ice Navy', palette: '#a7d3dd' },
    // { img: ColorViridianGreen, color: 'Viridian Green', palette: '#3f7772' },
    { img: ColorBeige, color: 'Beige', palette: '#d5cdc2' },
    { img: ColorGrey, color: 'Grey', palette: '#808388' },
  ]
  const renderColorCarousel = () => {
    return colorList.map((item, index) => (
      <div key={index}>
        <img loading='lazy'  alt={item.color} src={item.img} style={{ width: '100vw', marginBottom: '3rem', scrollSnapAlign: 'start' }} />
      </div>
    ))
  }
  const renderColorBar = () => {
    return colorList.map((item, index) => (
      <div style={currentColorIndex === index ? {
        display: 'inline-block',
        padding: '0.25rem',
        boxShadow: `0px 0px 10px 0px ${item.palette}`,
        borderRadius: '20rem',
      } : {
        padding: '0rem',
        boxShadow: 'none',
      }}>
        <div
          style={{ cursor: 'pointer', width: '5.5rem', height: '0.65rem', borderRadius: '20rem', backgroundColor: item.palette }}
          onClick={() => handleColorCarouselClick(index)}
        />
      </div>
    ))
  }
  const handleScrollColorCarousel = (direction) => {
    const container = document.getElementById('color-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }
  const handleColorCarouselClick = (index) => {
    setCurrentKVIndex(index)
    const carousel = document.getElementById('color-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'smooth' })
  }

  // ABOUT T-D
  const containerStyle = {
    backgroundColor: 'white',
    padding: '6.25rem 12.5rem 3.5rem 12.5rem',
    borderRadius: '1.125rem',
  }
  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '2.5rem',
    marginTop: '2.5rem',
    // gridTemplateAreas: `
    //   "header header"
    //   "content1 content2"
    //   "content3 content4"
    // `,
    position: 'relative',
  }
  const titleStyle = {
    fontFamily: 'Gilroy-ExtraBold',
    fontSize: '3rem',
    color: '#103856',
    marginBottom: '2rem',
    gridColumn: '1 / -1',
  }
  const gridItemStyle = (index) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    marginBottom: '4rem',
    position: 'relative',
    zIndex: index % 2 === 0 ? 2 : 1,
    marginTop: index % 2 !== 0 ? '-9rem' : '0',
  })
  const imageStyle = {
    width: '100%',
  }
  const headerStyle = {
    fontFamily: 'Gilroy-Bold',
    fontSize: '1.5rem',
    color: '#103856',
  }
  const subheaderStyle = {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1rem',
    color: '#71869C',
    marginTop: '-0.25rem',
  }
  const tdList = [
    {
      img: AboutTD1,
      header: 'Bagasi Luas',
      subheader: 'Nggak ada yang bisa ngalahin luasnya bagasi motor listrik Electrum H3i. Mulai dari tas, topi, sepatu, jas hujan, sampai helm full face, semua muat!',
    },
    {
      img: AboutTD2,
      header: 'Desain Modern',
      subheader: 'Dibawa ngantor, morning ride, atau hangout, Electrum H3i bakal jadi fashion statement favorit kamu.',
    },
    {
      img: AboutTD3,
      header: 'Ruang Lega',
      subheader: 'Area motornya yang lega, mulai dari ruang kaki sampai jok, bikin sensasi berkendara lebih nyaman.',
    },
    {
      img: AboutTD4,
      header: 'Lincah & Ringan',
      subheader: 'Bobotnya yang ringan bikin Electrum H3i jadi paling sat-set dan lincah di jalan. Jalanan berbelok-belok? Nggak masalah!',
    },
  ]
  const renderTD = () => {
    return tdList.map((item, index) => (
      <div ley={index} style={gridItemStyle(index % 2 !== 0)}>
        <img loading='lazy'  alt={`td${index+1}`} src={item.img} style={imageStyle} />
        <div style={headerStyle}>{item.header}</div>
        <div style={subheaderStyle}>{item.subheader}</div>
      </div>
    ))
  }

  // COLORED TEXT
  const ColoredText = styled.span`
    background: linear-gradient(0deg, #0B4571, #1ECECA);
    color: transparent;
    background-clip: text;
    font-family: Gilroy-Bold;
    font-size: 2rem;
  `

  // UNDERSTAND
  const understandArr = [
    {
      img: Understand1,
      title: 'Baterai Tahan Lama',
      description: 'Berbekal 1 buah baterai NCM yang tahan jarak tempuh hingga 65 km, riding ke tempat hangout after-office lebih dari cukup.',
    },
    {
      img: Understand2,
      title: '2 Mode Unggulan',
      description: '<b>Mode Sport</b> siap menjawab kebutuhan akselerasi dan kecepatan, sedangkan <b>Mode Smart</b> bantu kamu hemat energi tanpa mengurangi performa.',
    },
    {
      img: Understand3,
      title: 'Cruise Control & Reverse Assist',
      description: 'Fitur <b>Cruise Control</b> memungkinkan kamu aman berkendara dengan kecepatan konstan. Butuh bantuan mundur? Serahkan pada fitur <b>Reverse Assist</b>.',
    },
    {
      img: Understand4,
      title: 'Smart Key Remote',
      description: 'Dengan <b>Smart Key Remote</b>, kamu bisa menyalakan/mematikan motor, tanda alarm, hingga membuka jok. Semua kebutuhan dalam satu genggaman.',
    },
    {
      img: Understand5,
      title: 'Adaptive Speedometer',
      description: 'Tidak hanya menampilkan informasi performa motor dan baterai, warna panel speedometer dapat berubah secara adaptif mengikuti terang-gelapnya kondisi sekeliling saat berkendara.',
    },
    {
      img: Understand6,
      title: 'Pengisi daya USB',
      description: 'Nggak ada lagi cerita handphone-mu lowbat. Charge aja langsung lewat USB port di motor listrik Electrum kamu. Anti lowbat-lowbat club!',
    },
  ]
  const renderUnderstand = () => {
    return understandArr.map((item, index) => (
      <div key={index} style={{ flexShrink: 0, maxWidth: '19rem' }}>
      <img loading='lazy'  alt='understand' src={item.img} style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
        <div style={{ backgroundColor: 'white', borderRadius: '0 0 0.625rem 0.625rem', padding: '2rem 1.5rem', minHeight: '10.5rem', marginTop: '-0.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: '#103856', marginBottom: '0.65rem' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C' }}>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div style={{ backgroundColor: '#F4F7FA' }}>

      {/* BANNER CAROUSEL */}
      {/* <div>
        <div
          id="kv-carousel"
          style={{
            width: '100vw',
            height: '90vh',
            margin: '0 auto',
            backgroundColor: 'white',
            display: 'flex',
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
            position: 'relative',
          }}
          onScroll={(e) => {
            const scrollPosition = e.target.scrollLeft
            const newIndex = Math.round(scrollPosition / window.innerWidth)
            setCurrentKVIndex(newIndex)
          }}
        >
          {renderKVCarousel()}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '-3rem',
            position: 'relative',
            zIndex: '99',
          }}
        >
          {bannerList.map((slide, index) => (
            <div
              key={index}
              onClick={() => handleKVCarouselClick(index)}
              style={{
                width: '45px',
                height: '6.5px',
                borderRadius: '50rem',
                backgroundColor: currentKVIndex === index ? '#1ECECA' : '#4F939D',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            ></div>
          ))}
        </div>
      </div> */}

      {/* SINGLE BANNER */}
      <div
        alt="Banner Placeholder"
        style={{
          backgroundImage: `url('${ProductKVH3i}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '90vh',
          scrollSnapAlign: 'start',
        }}
      >
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '6.5rem' }}>
          {/* HEADLINE & SUBHEADLINE */}
          <div style={{ fontFamily: 'Gilroy-Bold', color: 'white', fontSize: '4.475rem' }}>
            Electrum H3i
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', color: 'white', fontSize: '2.5rem' }}>
            Your Reliable Lifestyle Partner
          </div>
          {/* BUTTONS */}
          <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
            <button style={btnStyleBlue} onClick={() => navigate('/get-yours')}>
              Dapatkan Sekarang <ArrowRight fill="#fff" />
            </button>
            <button style={btnStyleWhite} onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}>
              Daftar Test-ride <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>

      {/* INTRO */}
      <div style={{ padding: '10rem 12.5rem 12.5rem 12.5rem', backgroundColor: 'white', borderRadius: '0 0 6.25rem 6.25rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.125rem', color: '#103856', paddingLeft: '5rem', marginBottom: '1rem' }}>
          Partner Lifestyle Kamu
        </div>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.5rem', color: '#103856', paddingLeft: '5rem', marginBottom: '2.5rem' }}>
          Gaya Tanpa Polusi
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.25rem', color: '#71869C', paddingLeft: '5rem', marginBottom: '3.5rem', borderLeft: '0.5rem solid #1ECECA' }}>
        Electrum H3i adalah partner lifestyle buat kamu morning ride, ngafe, olahraga, sampai berburu dessert viral. Desainnya yang stylish bikin kamu jadi pusat perhatian ke mana pun kamu pergi. Dengan Electrum H3i, kamu bisa menjelajahi kota dengan gaya tanpa polusi. Yuk, jadi bagian dari komunitas Electrum sebagai Gen-E!
        </div>
        <img loading='lazy'  alt='rectangle-placeholder' src={IntroProduct} style={{ width: '100%' }} />
      </div>

      {/* VIDEO */}
      <div style={{ padding: '6.25rem 12.5rem 12.5rem 12.5rem' }}>
        <div style={{
          fontFamily: 'Gilroy-ExtraBold',
          fontSize: '3.5rem',
          color: '#103856',
          textAlign: 'center',
          marginBottom: '2.5rem',
        }}>
          Electrum H3i <br />
          Teman Aktivitas Sehari-hari
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            // src={H3iVideo}
            src='https://res.cloudinary.com/aldrichelectrum/video/upload/v1729581547/1021_k6adzt.mp4'
            autoPlay
            muted
            loop
            style={{
              width: '100%',
              borderRadius: '1.25rem',
              transition: 'width 0.5s ease-in-out',
            }}
          />
        </div>
      </div>

      {/* ABOUT TECH 2 */}
      <div style={containerStyle}>
        <div style={gridContainerStyle}>
          <div style={titleStyle}>
            Desain Stylish <br/>
            & Ergonomis
          </div>
          {renderTD()}
        </div>
      </div>

      {/* UNDERSTAND */}
      <div style={{ padding: '8rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: '#103856', textAlign: 'center', marginBottom: '3.5rem' }}>
          Teknologi yang <br />
          Mengerti Kamu
        </div>
        <div style={{ display: 'flex', gap: '1.5rem', overflowX: 'scroll' }}>
          {renderUnderstand()}
        </div>
      </div>

      {/* ABOUT TECH 1 */}
      <div style={{ padding: '3rem 12.5rem 8rem 12.5rem' }}>
        <div style={{
          fontFamily: 'Gilroy-ExtraBold',
          fontSize: '3.75rem',
          color: '#103856',
          textAlign: 'center',
          marginBottom: '5rem',
        }}>
          Efisiensi Adalah Kunci
        </div>
        {/* CARD 1 */}
        <div style={{ display: 'flex', width: '100%', marginBottom: '3.5rem' }}>
          <img loading='lazy'  alt='techdesign1' src={TechDesign1} style={{ width: '50%', backgroundColor: '#F4F7FA', borderRadius: '0 0 0 2.5rem' }} />
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            rowGap: '1rem',
            borderRadius: '0 3.125rem 3.125rem 0',
            backgroundColor: '#F4F7FA',
            padding: '5.5rem 3.5rem',
            backgroundColor: 'white',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.875rem', color: '#103856' }}>
              Hemat Biaya Bahan <br />
              Bakar Hingga 60%
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Cuma butuh 5 ribuan untuk sekali isi energi. Yes, kamu nggak salah lihat. Memang semurah itu biayanya untuk jarak hingga 65 km!
            </div>
          </div>
        </div>
        {/* CARD 2 */}
        <div style={{ display: 'flex', width: '100%', marginBottom: '3.5rem' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            rowGap: '1rem',
            borderRadius: '3.125rem 0 0 3.125rem',
            backgroundColor: '#F4F7FA',
            padding: '5.5rem 3.5rem',
            backgroundColor: 'white',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.875rem', color: '#103856' }}>
              Minim Perawatan
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Nggak ada lagi tuh, pengeluaran buat ganti oli, aki, dan lainnya. Lumayan, uangnya bisa kamu tabung buat nge-date, kan.
            </div>
          </div>
          <img loading='lazy'  alt='techdesign1' src={TechDesign2} style={{ width: '50%', backgroundColor: '#F4F7FA', borderRadius: '0 0 2.5rem 0' }} />
        </div>
        {/* CARD 3 */}
        <div style={{ display: 'flex', width: '100%' }}>
          <img loading='lazy'  alt='techdesign1' src={TechDesign3} style={{ width: '50%', backgroundColor: '#F4F7FA', borderRadius: '0 0 0 2.5rem' }} />
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            rowGap: '1rem',
            borderRadius: '0 3.125rem 3.125rem 0',
            backgroundColor: '#F4F7FA',
            padding: '5.5rem 3.5rem',
            backgroundColor: 'white',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.875rem', color: '#103856' }}>
              Hemat Waktu
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Hemat waktumu dengan fast charger yang siap isi ulang baterai dalam 3 jam, atau swap baterai dalam hitungan detik di ratusan BSS di Jadetabek.
            </div>
          </div>
        </div>
      </div>

      {/* CHARGE/SWAP */}
      <div style={{ padding: '6.25rem 12.5rem 12.5rem 12.5rem' }}>
        {/* TITLE SECTION */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856' }}>
            Charge Atau Swap? <br />
            Bisa Dua-duanya!
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '45%', marginTop: '0.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa. <br />
              Atau perlu nge-swap di jalan? Bisa. <br />
              Mau dua-duanya? Juga bisa!
            </div>
            <div>
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#0B4571',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'Gilroy-SemiBold',
                  padding: '0.75rem 1.5rem',
                  borderRadius: '0.5rem',
                  fontSize: '1rem',
                }}
                onClick={() => navigate('/energy')}
              >
                Tunjukkan Saya <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>
        {/* CARDS */}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.5rem', marginTop: '2.5rem' }}>
          {/* CHARGE */}
          <div>
            <img loading='lazy'  src={BatteryCharge} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#F4F7FA', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem', backgroundColor: 'white' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          {/* SWAP */}
          <div>
            <img loading='lazy'  src={BatterySwap} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#F4F7FA', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem', backgroundColor: 'white' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EFFICIENT */}
      {/* <div style={{ padding: '3rem 12.5rem 12.5rem 12.5rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856' }}>
            Efisien dan cepat, <br />
            Selalu tepat waktu
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '45%' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa.
              Atau perlu nge-swap di jalan? Juga bisa.
              Bebas pilih yang kamu suka.
            </div>
            <div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Tunjukkan Saya <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>
        <img loading='lazy'  alt='efficient-placeholder' src={EfficientPlaceholder} style={{ width: '100%', marginTop: '2.5rem' }} />
      </div> */}

      {/* ENERGY OPTIONS */}
      <div style={{ padding: '3rem 0rem' }}>
        <div style={{
          backgroundImage: `url('${PackageBG}')`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          padding: '5.5rem 12.5rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.5rem', color: '#103856', textAlign: 'center', marginBottom: '1.25rem' }}>
            Pilihan Paket
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.25rem', color: '#71869C', textAlign: 'center', marginBottom: '2.25rem' }}>
            Tersedia 2 pilihan paket kepemilikan baterai, yang tidak mempengaruhi fleksibilitas cara kamu mengisi ulang energi (charge maupun swap).
          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3.5rem' }}>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                border: '2.5px solid #0B4571',
                color: '#0B4571',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Pelajari Lebih Lanjut <ArrowRight fill="#fff" />
            </button>
          </div> */}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.25rem' }}>
            {/* CHARGE */}
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
              <div style={{
                padding: '1.25rem 2rem',
                backgroundColor: 'white',
                borderRadius: '0.85rem',
                minHeight: '19rem',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px',
              }}>
                <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div>Garansi baterai 5 tahun/50 ribu km</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Home Service 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* SWAP */}
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PSB' src={PSB} style={{ width: '100%' }} />
              <div style={{
                padding: '1.25rem 2rem',
                backgroundColor: 'white',
                borderRadius: '0.85rem',
                minHeight: '19rem',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px',
              }}>
                <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> sewa baterai selama 18 bulan</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CHOOSE COLOR */}
      <div style={{ padding: '10rem 0rem 12.5rem 0rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.75rem', color: '#103856', marginBottom: '3.125rem', textAlign: 'center' }}>
          Pilih Warna <span style={{ color: '#1ECECA' }}>Sesuai Gayamu</span>
        </div>
        {/* IMG PREVIEW */}
        <div style={{ position: 'relative', width: '100%' }}>
          {/* PREV BUTTON */}
          <button
            onClick={() => handleScrollColorCarousel(-1)}
            style={{
              position: 'absolute',
              top: '43.5%',
              left: '5rem',
              transform: 'translateY(-43.5%)',
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              borderRadius: '50%',
              padding: '0.5rem 0.625rem',
              cursor: 'pointer',
              fontSize: '1.5rem',
              display: currentColorIndex === 0 ? 'none' : 'block',
              zIndex: 1,
            }}
          >
            <LeftOutlined style={{ color: '#1ECECA' }} />
          </button>
          {/* SCROLLABLE CAROUSEL */}
          <div
            id="color-carousel"
            style={{
              width: '100vw',
              margin: '0 auto',
              display: 'flex',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
            }}
            onScroll={(e) => {
              const scrollPosition = e.target.scrollLeft;
              const newIndex = Math.round(scrollPosition / window.innerWidth);
              setCurrentColorIndex(newIndex);
            }}
          >
            {renderColorCarousel()}
          </div>
          {/* NEXT BUTTON */}
          <button
            onClick={() => handleScrollColorCarousel(1)}
            style={{
              position: 'absolute',
              top: '43.5%',
              right: '5rem',
              transform: 'translateY(-43.5%)',
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              borderRadius: '50%',
              padding: '0.5rem 0.625rem',
              cursor: 'pointer',
              fontSize: '1.5rem',
              display: currentColorIndex === colorList.length - 1 ? 'none' : 'block',
              zIndex: 1,
            }}
          >
            <RightOutlined style={{ color: '#1ECECA' }} />
          </button>
        </div>
        {/* COLOR SELECTOR */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '3.75rem' }}>
          <div style={{
            padding: '2rem',
            display: 'flex',
            alignItems: 'center',
            columnGap: '1.5rem',
            boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
            width: 'fit-content',
            borderRadius: '1.25rem',
            marginBottom: '1.25rem',
            backgroundColor: 'white',
          }}>
            {renderColorBar()}
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem' }}>
            {colorList[currentColorIndex].color}
          </div>
        </div>
        {/* SPECIFICATION */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            backgroundColor: '#FFFFFF',
            padding: '3rem',
            borderRadius: '1.25rem',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            columnGap: '2rem',
            rowGap: '2.5rem',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                65 km/jam
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Kecepatan Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                65 km
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Jarak Tempuh Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                1.8 KwH
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Kapasitas Baterai
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                Cakram Ganda
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Sistem Pengereman
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                150 Nm
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Torsi Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                3 kW
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Output Tenaga Maks.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GET NOW */}
      <div style={{ padding: '5rem 0rem 7.5rem 0rem' }}>
        <div style={{ width: '85%', position: 'relative' }}>
          <img loading='lazy'  alt='getnow-placeholder' src={GetNowPlaceholder} style={{ width: '100%' }} />
          <div style={{
            width: '35%',
            padding: '3.5rem 5rem 3.5rem 8.5rem',
            backgroundColor: 'white',
            borderRadius: '0 1.125rem 1.125rem 0',
            position: 'absolute',
            top: '80%',
            left: '0',
            transform: 'translateY(-80%)',
          }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', marginBottom: '1rem' }}>
              Dapatkan Sekarang!
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginBottom: '3.5rem' }}>
              Tersedia pilihan paket Electrum yang menarik melalui variasi metode pembayaran yang kamu inginkan.
            </div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan H3i <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProductH3i
