import * as React from "react";

const ArrowDown = ({ width = "25", height = "14" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 25 14"
  >
    <g fill="currentColor" clipPath="url(#clip0_6529_675)">
      <rect
        width="2.987"
        height="17.92"
        rx="1.493"
        transform="rotate(-45 2.018 .836)"
      ></rect>
      <rect
        width="2.987"
        height="17.92"
        rx="1.493"
        transform="rotate(45 12.148 27.82)"
      ></rect>
    </g>
    <defs>
      <clipPath id="clip0_6529_675">
        <path fill="#fff" d="M0 0h25v14H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default ArrowDown;
