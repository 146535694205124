import React, { useContext, useState } from 'react'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom'
import { ElectrumBlue } from 'assets/icons'
import { FormattedMessage } from 'react-intl'
import { H1, H3, H3i, H5 } from 'assets/images'

const defaults = require('languages/id.json')

const Product = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const [showBikeType, setShowBikeType] = useState(false)

  // ROUTES
  const routesArr = [
    {
      name: 'header_home',
      route: '/',
    },
    {
      name: 'header_bike',
      route: '/products/h3i',
    },
    {
      name: 'header_energy',
      route: '/energy',
    },
    {
      name: 'header_getYours',
      route: '/get-yours',
    },
    {
      name: 'header_aboutUs',
      route: '/about-us',
    },
    {
      name: 'header_news',
      route: '/news',
    },
  ]
  const renderRouteNavigations = () => {
    return routesArr.map((item, index) => (
      <div
        key={index}
        style={{
          cursor: 'pointer',
          fontFamily: 'Gilroy-Bold',
          fontSize: '1.125rem',
          color: '#103856',
          width: 'fit-content',
          borderBottom: pathname === item.route ? '0.175rem solid #1ECECA' : 'none',
        }}
        onClick={() => navigate(item.route)}
      >
        <FormattedMessage
          id={item.name}
          defaultMessage={defaults[item.name]}
        />
      </div>
    ))
  }

  return (
    <div>

      {/* MAIN HEADER */}
      <div style={{
        position: 'relative',
        height: pathname.includes('/products') ? '10.25rem' : '4.125rem',
        // height: '4.125rem',
      }}>
        <div style={{
          position: 'fixed',
          width: '100vw',
          top: '0',
          zIndex: 999,
        }}>

          {/* HEAD */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: 'white',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>

          {/* CONTENT */}
          {showDropdown && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'fixed',
              width: '100vw',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              padding: '0.5rem 0rem 1rem 0rem',
              borderRadius: '0 0 1rem 1rem',
              marginTop: '-0.25rem',
              boxShadow: '0px 7.5px 10px -5px rgba(0,0,0,0.125)',
            }}>
              {renderRouteNavigations()}
            </div>
          )}

          {/* PRODUCT TAB */}
          {/* {pathname.includes('/products') && ( */}
          {true && (
            <div style={{
              backgroundColor: '#F4F9FA',
              display: 'flex',
              justifyContent: 'center',
              columnGap: '1rem',
              paddingTop: '1rem',
              position: 'fixed',
              top: '4rem',
              width: '100%',
            }}>
              <div
                // onClick={() => setSelectedProduct('H3')}
                style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <img loading='lazy' src={H3} alt='H3' height={45} />
                <div
                  style={{
                    fontFamily: 'Gilroy-SemiBold',
                    fontSize: '0.85rem',
                    color: '#103856',
                    marginTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    borderBottom: 'none',
                  }}
                >
                  ELECTRUM H3
                </div>
              </div>
              <div
                // onClick={() => setSelectedProduct('H5')}
                style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <img loading='lazy' src={H5} alt='H5' height={45} />
                <div
                  style={{
                    fontFamily: 'Gilroy-SemiBold',
                    fontSize: '0.85rem',
                    color: '#103856',
                    marginTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    borderBottom: 'none',
                  }}
                >
                  ELECTRUM H5
                </div>
              </div>
              <div
                // onClick={() => setSelectedProduct('H3i')}
                style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <img loading='lazy' src={H3i} alt='H3i' height={45} />
                <div
                  style={{
                    fontFamily: 'Gilroy-SemiBold',
                    fontSize: '0.85rem',
                    color: '#103856',
                    marginTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    borderBottom: 'none',
                  }}
                >
                  ELECTRUM H3i
                </div>
              </div>
              <div
                // onClick={() => setSelectedProduct('H1')}
                style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <img loading='lazy' src={H1} alt='H1' height={45} />
                <div
                  style={{
                    fontFamily: 'Gilroy-SemiBold',
                    fontSize: '0.85rem',
                    color: '#103856',
                    marginTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    borderBottom: 'none',
                  }}
                >
                  ELECTRUM H1
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  )
}

export default Product
